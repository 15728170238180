$heroHeight: 280px;

.outerWrapper {
  background-image: url("/tmi-landing-hero-banner.png");
  background-repeat: no-repeat;
  background-size: 100% $heroHeight;
}

.content {
  padding-left: 60px;
  padding-right: 60px;
}

.hero {
  max-width: 50%;
  color: #fff;
  height: $heroHeight;
  display: flex;
  justify-content: left;
  align-items: center;
}

/* stylelint-disable-next-line selector-max-type */
.hero h1 {
  color: #a5f8f3;
}

.heroHeader {
  font-size: 2.5rem;
}

.heroSubtext {
  font-size: 1rem;
}

.explore {
  padding-top: 25px;
}

.spotlightTools {
}

/* stylelint-disable-next-line selector-max-type */
.spotlightTools a,
/* stylelint-disable-next-line selector-max-type */
.spotlightTools a:hover .spotlightTools a:visited,
/* stylelint-disable-next-line selector-max-type */
.spotlightTools a:active {
  color: inherit;
  text-decoration: none;
}

.spotlightTile {
  padding: 30px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spotlightTile:hover {
  box-shadow: 0 0 0 2px #0e184b inset;
}

/* stylelint-disable-next-line selector-max-type */
.spotlightTile h2 {
  color: #dc844b;
}
